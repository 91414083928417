export const homeAbout = [
  {
    id: 1,
    cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
    title: "Online Courses",
    desc: "Online courses let you learn from anywhere in the world. Whether you're looking for a career boost or a personal passion project.",
  },
  {
    id: 2,
    cover: "https://img.icons8.com/pastel-glyph/64/intelligent-person.png",
    title: "Learn with Industries Expert",
    desc: "Gain real-world knowledge, Learn from industry experts who can share practical insights and the latest trends in your field.",
  },
  {
    id: 3,
    cover: "https://img.icons8.com/ios/50/mental-state.png",
    title: "1:1 Doubt Clearnes",
    desc: "Get personalized support, One-on-one doubt clearance ensures your questions get focused attention and tailored explanations.",
  },
  {
    id: 4,
    cover: "https://img.icons8.com/ios/50/interview.png",
    title: "Mock Interview Practice",
    desc: "Mock interviews provide a safe space to rehearse your answers, overcome nerves, and impress potential employers.",
  },
  {
    id: 5,
    cover: "https://img.icons8.com/ios/80/000000/diploma.png",
    title: "Earn A Certificates",
    desc: "Earning a certificate demonstrates your dedication and accomplishment in a particular field to potential employers or clients.",
  },
  {
    id: 6,
    cover: "https://img.icons8.com/ios/50/new-job.png",
    title: "Internship Opportunity",
    desc: "Internships provide a hands-on way to explore different career options and see if a particular field aligns with your interests and strengths.",
  },
]
export const awrapper = [
  {
    cover: "https://img.icons8.com/external-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto/80/ffffff/external-graduation-education-yogi-aprelliyanto-basic-outline-yogi-aprelliyanto.png",
    data: "3,000",
    title: "SUCCESS STORIES",
  },

  {
    cover: "https://img.icons8.com/ios/80/ffffff/athlete.png",
    data: "320",
    title: "TRUSTED TUTORS",
  },
  {
    cover: "https://img.icons8.com/external-outline-icons-maxicons/80/ffffff/external-calender-insurance-outline-outline-icons-maxicons.png",
    data: "1,000",
    title: "SCHEDULES",
  },
  {
    cover: "https://img.icons8.com/ios/80/ffffff/macbook-idea--v3.png",
    data: "587",
    title: "COURSES",
  },
]
export const coursesCard = [
  {
    id: 1,
    cover_logo: "../images/courses/c1.png",
    cover_image: "https://png.pngtree.com/png-vector/20220723/ourmid/pngtree-software-development-and-programming-code-on-computer-vector-illustration-for-technology-png-image_6046829.png",
    coursesName: "DSA and Competitive Coding Bootcamp",
    courseStatus: "Course Syllabus",
    courseHeading: "Learns DSA and competitive coding from Industries Expert",
    courseDescription: "Competitive programming is a sport of coding where participants solve problems using efficient algorithms within a specified time frame, aiming for optimal solutions.",
    cousreTopics:[
         "C++ quick Introduction",
         "About STL",
         "Time complexity and Space Complexity",
         "Array",
         "String",
         "Searching and Sorting",
         "Recusrion and Backtraking",
         "Bitwise Operation",
         "Intervals",
         "LinkedList",
         "Matrix",
          "Tree",
          "Heap",
          "Graph",
          "Dynamic Programming",
          "Segmented Tree",
          "Trie",
          "Disjoint Set",

    ],
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by John Smith",
        totalTime: "50 lectures (190 hrs)",
      },
    ],
    priceAll: "$100 All Course",
    pricePer: "$15 per month",
  },
  {
    id: 2,
    cover_logo: "../images/courses/c2.png",
    cover_image: "https://cdni.iconscout.com/illustration/premium/thumb/software-developer-1946892-1651592.png",
    coursesName: "Natural language processing & Deep Learning",
    courseStatus: "Course Syllabus",
    courseHeading: "Learn Natural language processing (NLP) and Deep learning with Data science Experties",
    courseDescription: "Unlock the power of text and data! Master the skills to become a Data Science Pro and propel your career to new heights.",
    cousreTopics:[
         "Text Processing",
         "Exploratry Data Analysis",
         "Tokenization",
         "Stemming",
         "Lemmatization",
         "Stopwords",
         "Bag of Words",
         "TF-IDF",
         "Word2Vec",
         "N-gram",
         "Uni and Bi Variate Analysis",
         "ANN",
         "Activation Function",
         "RNN",
         "LSTM",
         "Seq2seq",
         "Encoder Decoder",
         "Self Attention",
          "Transformer",
          "Bert",
          "Evaluation Metrics",

    ],
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Ram Gurung",
        totalTime: "30 lectures (125 hrs)",
      },
    ],
    priceAll: "$200 All Course",
    pricePer: "$25 per month",
  },
  {
    id: 3,
    cover_logo: "../images/courses/c3.png",
    cover_image: "../images/courses/Data2.png",
    coursesName: "Basic to advance Classical Machine Learning",
    courseStatus: "Course Syllabus",
    courseHeading: "Learn Classical Machine Learning with Data science Experties",
    courseDescription: "Build a strong foundation in Classical Machine Learning. Advance to complex data science techniques with expert guidance.",
     cousreTopics:[
          "Numpy",
          "Pandas",
          "Statistics",
          "Graphs",
          "Linear Regression",
          "Logistic Regression",
          "SVM",
          "Naive base algorithm",
          "KNN algorithm",
          "K-means Clustering",
          "Principal of component analysis",
          "Decision Tree",
          "Random Forest Algorithm",
          "Boosting Algorithm",
          
 
     ],
    courTeacher: [
      {
        dcover: "./images/back.webp",
        name: "by Saroj Nepal",
        totalTime: "50 lectures (50 hrs)",
      },
    ],
    priceAll: "$50 All Course",
    pricePer: "$5 per month",
  },
  // {
  //   id: 4,
  //   cover: "../images/courses/c4.png",
  //   coursesName: "Data Science and Deep Learning",
  //   courseStatus: "upcoming soon",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Prabin Thapa Mager",
  //       totalTime: "20 lectures (20 hrs)",
  //     },
  //   ],
  //   priceAll: "$30 All Course",
  //   pricePer: "$3 per month",
  // },
  // {
  //   id: 5,
  //   cover: "../images/courses/c5.png",
  //   coursesName: "Introducing to Programming with ReactJS",
  //   courseStatus: "upcoming soon",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Sunil Bishowkaram",
  //       totalTime: "100 lectures (150 hrs)",
  //     },
  //   ],
  //   priceAll: "$300 All Course",
  //   pricePer: "$30 per month",
  // },
  // {
  //   id: 6,
  //   cover: "../images/courses/c6.png",
  //   coursesName: "Learn Frontend Programming Language",
  //   courseStatus: "upcoming soon",
  //   courTeacher: [
  //     {
  //       dcover: "./images/back.webp",
  //       name: "by Sunil Bishowkaram",
  //       totalTime: "200 lectures (300 hrs)",
  //     },
  //   ],
  //   priceAll: "$500 All Course",
  //   pricePer: "$80 per month",
  // },
  
]
export const online = [
  {
    cover: "./images/courses/online/o1.png",
    hoverCover: "./images/courses/online/o1.1.png",
    courseName: "UI/UX Design Courses",
    course: "25 Courses",
  },
  {
    cover: "./images/courses/online/o2.png",
    hoverCover: "./images/courses/online/o2.1.png",
    courseName: "Art & Design",
    course: "25 Courses",
  },
  {
    cover: "./images/courses/online/o3.png",
    hoverCover: "./images/courses/online/o3.1.png",
    courseName: "Computer Science",
    course: "10 Courses",
  },
  {
    cover: "./images/courses/online/o4.png",
    hoverCover: "./images/courses/online/o4.1.png",
    courseName: "History & Archeologic",
    course: "15 Courses",
  },
  {
    cover: "./images/courses/online/o5.png",
    hoverCover: "./images/courses/online/o5.1.png",
    courseName: "Software Engineering",
    course: "30 Courses",
  },
  {
    cover: "./images/courses/online/o6.png",
    hoverCover: "./images/courses/online/o6.1.png",
    courseName: "Information Software",
    course: "60 Courses",
  },
  {
    cover: "./images/courses/online/o7.png",
    hoverCover: "./images/courses/online/o7.1.png",
    courseName: "Health & Fitness",
    course: "10 Courses",
  },
  {
    cover: "./images/courses/online/o8.png",
    hoverCover: "./images/courses/online/o8.1.png",
    courseName: "Marketing",
    course: "30 Courses",
  },
  {
    cover: "./images/courses/online/o9.png",
    hoverCover: "./images/courses/online/o9.1.png",
    courseName: "Graphic Design",
    course: "80 Courses",
  },
  {
    cover: "./images/courses/online/o10.png",
    hoverCover: "./images/courses/online/o10.1.png",
    courseName: "Music",
    course: "120 Courses",
  },
  {
    cover: "./images/courses/online/o11.png",
    hoverCover: "./images/courses/online/o11.1.png",
    courseName: "Business Administration",
    course: "17 Courses",
  },
  {
    cover: "./images/courses/online/o12.png",
    hoverCover: "./images/courses/online/o12.1.png",
    courseName: "Web Management",
    course: "17 Courses",
  },
]
export const team = [
  // {
  //   cover: "./images/team/sid.jpg",
  //   name: "Siddhatha Mishra",
  //   work: "IIT Dhanbad, Data Scientist",
  //   industryExp: "Industry Experience : 8+ years",
  // },
  {
    cover: "./images/team/kanchan_pic.jpg",
    name: " Kanchan Sharma",
    work: "NIT Durgapur, Full Stack Developer",
    industryExp: "Industry Experience : 5+ years",
  },
  {
    cover: "./images/team/anushka.png",
    name: "Anushka Kumari",
    work: "Data Scientist",
    industryExp: "Industry Experience : 3+ years",
  },
  // {
  //   cover: "./images/team/t4.webp",
  //   name: "Ph.D Brian Wooden",
  //   work: "DEVELOPER AND LEAD INSTRUCTOR",
  // },
  // {
  //   cover: "./images/team/t5.webp",
  //   name: "Ph.D Adrian Molises",
  //   work: "DEVELOPER AND LEAD INSTRUCTOR",
  // },
  // {
  //   cover: "./images/team/t6.webp",
  //   name: "Ph.D Arthur MaGregor",
  //   work: "DEVELOPER AND LEAD INSTRUCTOR",
  // },
  // {
  //   cover: "./images/team/t7.webp",
  //   name: "Ph.D Anna Hanzen",
  //   work: "DEVELOPER AND LEAD INSTRUCTOR",
  // },
  // {
  //   cover: "./images/team/t8.webp",
  //   name: "Ph.D Brian Wooden",
  //   work: "DEVELOPER AND LEAD INSTRUCTOR",
  // },
]
export const price = [
  {
    name: "BASIC PLAN",
    price: "49K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "BEGINNER PLAN",
    price: "79K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "PREMIUM PLAN",
    price: "109k",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "ULTIMATE PLAN",
    price: "149K",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
]
export const faq = [
  {
    title: "How to Enroll This Online Courses?",
    desc: "This is the first item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "Where It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "How It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "How to Enroll This Online Courses?",
    desc: "This is the first item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "Where It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "How It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
]
export const blog = [
  {
    id: 1,
    type: "admin",
    date: "JAN. 18, 2021",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b1.webp",
  },
  {
    id: 2,
    type: "admin",
    date: "API. 25, 2022",
    com: "5 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b2.webp",
  },
  {
    id: 3,
    type: "user",
    date: "MAY. 15, 2022",
    com: "10 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b3.webp",
  },
  {
    id: 4,
    type: "admin",
    date: "JAN. 02, 2022",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b4.webp",
  },
  {
    id: 5,
    type: "admin",
    date: "DEC. 14, 2022",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b5.webp",
  },
  {
    id: 6,
    type: "user",
    date: "JAN. 18, 2021",
    com: "12 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b6.webp",
  },
]
export const testimonal = [
  {
    id: 1,
    name: "ROGER SCOTT",
    post: "MARKETING MANAGER",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t1.webp",
  },
  {
    id: 2,
    name: "ROGER SCOTT",
    post: "MARKETING MANAGER",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t2.webp",
  },
  {
    id: 3,
    name: "ROGER SCOTT",
    post: "MARKETING MANAGER",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
    cover: "./images/testo/t3.webp",
  },
]
