import "./courses.css"
import { coursesCard } from "../../dummydata"
import Heading from "../common/heading/Heading"
import Awrapper from "../about/Awrapper"
import { Link } from "react-router-dom"
import { useState } from "react"




const CoursesCard = () => {
  const [selectedCourse, setSelectedCourse] = useState(null);

  const handleButtonClick = (courseData) => {
    setSelectedCourse(courseData);
      console.log(selectedCourse);
    
  };





  return (
    <>
      <section className='coursesCard'>
        <div className='container'>
        <Heading subtitle='our courses' title='explore our popular online courses' />
       
        <div className='grid2'>
          {coursesCard.map((val) => (
            <div key={val.id} className='items'>
              <div className='content flex'>
                 <div className='img'>
                        <img src={val.cover_logo} alt='' />
                </div>
                <div className='text'>
                  <h1>{val.coursesName}</h1>
                </div>
                <Link to = {`/syllabus/${val.id}`}><button className='outline-btn' 
                  onClick={() => handleButtonClick(val)}>{val.courseStatus}</button></Link>
              </div>
              
            </div>
          ))}
        </div>
        
        </div>
      </section>
      <Awrapper/>
    </>
  )
}

export default CoursesCard
