import React, { useEffect, useState } from 'react';
import "./content.css"
import { coursesCard } from '../../dummydata';
import { useParams } from 'react-router-dom';
import Awrapper from '../about/Awrapper';
import Heading from '../common/heading/Heading';

const CourseDetails = () => {
    const { id } = useParams();
    console.log(id);
    const [course, setCourse] = useState(null);

    useEffect(() => {
        const selectedCourse = coursesCard.find((course) => course.id === parseInt(id));
        setCourse(selectedCourse);
      }, [id]);
    
      if (!course) {
        return <div>Loading...</div>;
      }
    return (
        <>
       <section className='courseDetail'>
        <div className='course_container'>
          <div className='content'>
            <h1>{course.courseHeading}</h1>
            <p>"{course.courseDescription}"</p>
          </div>
          <div className='image-container'>
            <img src={course.cover_image} alt='front'></img>
          </div>
            
        </div>
           

      <div className='container'>
        <Heading title='Curriculum' />
          
            <div className='course_grid'>
            {course.cousreTopics.map((topic, index) =>{
              return(
              <div className='item flexSB' key={index}>
                {topic}
                {/* <ul>
                 
                  <li style={{ textAlign: 'center' }}>{topic}</li>
                
                </ul> */}
              </div> )
            })}
              
          </div>
        </div>
           
        </section>

        <Awrapper/>
        </>
        
      );
};

export default CourseDetails;
