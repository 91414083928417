import React, { useState, useEffect} from "react"
import "./contact.css"
import Awrapper from "../about/Awrapper"
import { useRef } from 'react';
import emailjs from '@emailjs/browser';


const Contact = () => {
  // const map = 'https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d904726.6131739549!2d85.24565535!3d27.65273865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2snp!4v1652535615693!5m2!1sen!2snp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" '

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  
  const [messageSent, setMessageSent] = useState(false);

  useEffect(() => {
    if (messageSent) {
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    }
  }, [messageSent]);
  
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_dtm2l2t', 'template_koan5ba', form.current, {
        publicKey: '1gDeMNzbR5Q2T7WbW',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setMessageSent(true);
          setTimeout(() => {
            setMessageSent(false);
          }, 2000);
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <>
      {/* <Back title='Contact us' /> */}
      <section className='contacts padding'>
        <div className='container shadow flexSB'>
          <div className='left row'>
          <h1>Contact us</h1>
            <p>We're open for any suggestion or just to have a chat</p>
            <div className='items grid2'>
              <div className='box'>
                <h4> <ion-icon name="location-outline"></ion-icon> Address</h4>
                <p> Noida, Uttar Pradesh, India</p>
              </div>
              <div className='box'>
                <h4> <ion-icon name="mail-outline"></ion-icon> Email</h4>
                <p> support@techfilic.com</p>
              </div>
              <div className='box'>
                <h4> <ion-icon name="call-outline"></ion-icon> Phone</h4>
                <p> +91- 7905118695</p>
              </div>
            </div>
            {/* <iframe src={map}></iframe> */}
          </div>
          <div className='right row'>
            {/* <h1>Contact us</h1>
            <p>We're open for any suggestion or just to have a chat</p> */}

            {/* <div className='items grid2'>
              <div className='box'>
                <h4>ADDRESS:</h4>
                <p>198 West 21th Street, Suite 721 New York NY 10016</p>
              </div>
              <div className='box'>
                <h4>EMAIL:</h4>
                <p> info@yoursite.com</p>
              </div>
              <div className='box'>
                <h4>PHONE:</h4>
                <p> + 1235 2355 98</p>
              </div>
            </div> */}

            <form ref={form} onSubmit={sendEmail}>
              <div className='flexSB'>
                <input type='text' placeholder='Name' name="user_name"
                 value={formData.name}
                 onChange={(e) => setFormData({ ...formData, name: e.target.value })}/>
                <input type='email' placeholder='Email' name="user_email"
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}/>
              </div>
              <input type='text' placeholder='Mobile No. ' name="from_name"
              value={formData.phone}
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
              <textarea cols='30' rows='10' name="message" placeholder="Create a message here..."
              value={formData.message}
              onChange={(e) => setFormData({ ...formData, message: e.target.value })}>
                
              </textarea>
              <button className='primary-btn'>SEND MESSAGE</button>
            </form>

            <h3>Follow us here</h3>
            <span>FACEBOOK TWITTER INSTAGRAM</span>
          </div>
        </div>
        {messageSent && (
        <div className="popup-message">
          <p>Your message has been sent successfully!</p>
      </div>
        )}
      </section>
      <Awrapper />
    </>
  )
}

export default Contact
