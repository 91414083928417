import CourseDetails from "./CourseDetails";
import "./content.css"
const MainSyllabus = () => {
    return (
        <>
        <CourseDetails/>
        </>
    )

}
export default MainSyllabus;